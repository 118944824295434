/* ----------------------------------------------------------------------------
 *
 * COMPONENT: OUR MODAL MESSAGE
 * Intended for only very short messages. Message box grows with content,
 * so this component is not appropriate for content that might exceed
 * the height of the viewport on any device.
 *
 * Bonus! Body properties to prevent scrolling when the modal is open.
 *
 *--------------------------------------------------------------------------- */

/*--------------------------------------
*
* COMPONENT: OUR MODAL MESSAGE -- ROBOTO FONTS
*
*-------------------------------------*/

/* Roboto at 400 weight is necessary for the typography in this component. */
/* Roboto at 500 weight is necessary for the button in this component. */
/* If Google Fonts are already imported into the app, append "Roboto:400,500,700" if necessary. */
/* If Google Fonts are not already imported, uncomment the following import statement or (better) add
the equivalent to the <head> of the app. */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin'); */

/*--------------------------------------
 *
 * COMPONENT: OUR MODAL MESSAGE -- VARIABLES
 *
 *-------------------------------------*/

:root {

  /* Color palette */

  --modal-teal:       #008470;
  --modal-light-gray: #ebebeb; /* 8% black */
  --modal-white:      #ffffff;

  /*
    Typographic sizes

  */

  --modal-font-base-min: 16px;
  --modal-font-base-max: 20px;
  --modal-fluid-em:      calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
  --modal-beta:          1.784em;
  --modal-gamma:         1.2em;
  --modal-delta:         1em;
  --modal-zeta:          .875em;
  --modal-eta:           .75em;

}

/* Viewport Width Variables */

:root {

  --modal-bp-min:          320px;
  --modal-bp-mega:         1024px;
  --modal-bp-max:          1320px;

}

/* Custom Media Declarations for Breakpoints */

@custom-media --modal-bp-min (min-width: 320px);
@custom-media --modal-bp-mega (min-width: 1024px);
@custom-media --modal-bp-max (min-width: 1320px);

/*--------------------------------------
*
* COMPONENT: OUR MODAL MESSAGE -- TYPOGRAPHY
*
*-------------------------------------*/

.c-modal {
  font-size: var(--modal-font-base-min);
  font-family: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap; /* https://www.zachleat.com/web/comprehensive-webfonts/#font-display */
  line-height: 1.35;

  /* Fluid from 16px to 20px between breakpoints */

  /* Fluid type size from 16px to 20px between --modal-bp-min and --modal-bp-max breakpoints */

  @media (--modal-bp-min) {
    & {
      font-size: var(--modal-font-base-min); /* 16px at 320px */
      font-size: calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
    }
  }

  @media (--modal-bp-max) {
    & {
      font-size: var(--modal-font-base-max); /* 20px at 1320px */
    }
  }

  /* Fluid line-height from 1.35 to 1.45 between --modal-bp-min and --modal-bp-max breakpoints */

  @media (--modal-bp-min) {
    line-height: 1.35; /* 1.35 at 320px */
    line-height: calc(1.35em + (1.45 - 1.35) * ((100vw - 320px)/(1320 - 320)));
  }

  @media (--modal-bp-max) {
    line-height: 1.45; /* 1.45 at 1320px */
  }


}

/*--------------------------------------
*
* COMPONENT: OUR MODAL MESSAGE -- STYLES
*
*-------------------------------------*/

body.l-has-modal {
  overflow-y: hidden;
}

.c-modal {
  display: none;
}

.c-modal * {
  box-sizing: border-box;
}

body.l-has-modal .c-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 8675310;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85) !important;
}

.c-modal__content {
  margin-left: 5vw;
  margin-right: 5vw;
  padding: 2em;
  z-index: 8675310;
  background: var(--modal-teal);
  color: var(--modal-white);
  box-shadow: 0 25px 100px rgba(0, 0, 0, 0.5);
  border-radius: .25em;
  text-align: center;
  position: relative;
}

@media (--modal-bp-mega) {
  .c-modal__content {
    margin-right: 15vw;
    margin-left: 15vw;
  }
}

.c-modal p {
  margin-bottom: 1em;
  font-weight: 400;
}

a.c-modal__close,
a.c-modal__close:link,
a.c-modal__close:visited
a.c-modal__close:active {
  position: absolute;
  text-decoration: none;
  top: calc(.125 * var(--modal-fluid-em));
  right: calc(.5 * var(--modal-fluid-em));
  font-size: var(--modal-beta);
  line-height: 1;
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--modal-white);
  cursor: pointer;
  opacity: .8;
  transition: none;
}

a.c-modal__close:focus,
a.c-modal__close:hover {
  opacity: 1;
  color: var(--modal-white);
}

a.c-modal__cancel,
a.c-modal__cancel:link,
a.c-modal__cancel:visited
a.c-modal__cancel:active {
  font-size: var(--modal-eta);
  background-color: transparent;
  color: var(--modal-light-gray);
  cursor: pointer;
  display: block;
  font-weight: 500;
  min-height: 2.375em;
  line-height: 1.2;
  text-align: center;
  text-decoration: underline;
  appearance: none;
  margin: calc(.25 * var(--modal-fluid-em)) 0 0;
  -webkit-appearance: none;
  transition: none;
}

a.c-modal__cancel:focus,
a.c-modal__cancel:hover {
  color: var(--modal-white);
}

.c-modal__content {
  & > h1 {
    font-size: var(--modal-gamma);
    font-weight: 700;
  }

  & > a[role="button"],
  & > a[role="button"]:link,
  & > a[role="button"]:visited
  & > a[role="button"]:active {
    font-size: var(--modal-eta);
    background-color: transparent;
    border: calc(.0625 * var(--modal-fluid-em)) solid var(--modal-light-gray);
    border-radius: calc(.25 * var(--modal-fluid-em));
    color: var(--modal-light-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    min-height: 2.375em;
    line-height: 1.2;
    padding: .75em 1.875em;
    text-align: center;
    text-decoration: none;
    appearance: none;
    margin: calc(.25 * var(--modal-fluid-em)) 0 0;
    -webkit-appearance: none;
    transition: none;
  }

  & > a[role="button"]:focus,
  & > a[role="button"]:hover {
    background-color: transparent;
    border-color: var(--modal-white);
    color: var(--modal-white);
  }

  & > a[rel="alternate"],
  & > a[rel="alternate"]:link,
  & > a[rel="alternate"]:visited
  & > a[rel="alternate"]:active {
    font-size: var(--modal-zeta);
    position: relative;
    padding-right: 2.5em;
    margin: calc(.5 * var(--modal-fluid-em)) 0;
  }

  & > a[rel="alternate"]::after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    right: .125em;
    content: "";
    width: 0;
    height: 0;
    border-top: transparent solid .5em;
    border-right: transparent solid .75em;
    border-bottom: transparent solid .5em;
    border-left: var(--modal-light-gray) solid .75em;
    margin-left: auto;
  }

  & > a[rel="alternate"]:focus::after,
  & > a[rel="alternate"]:hover::after {
    border-left-color: var(--modal-white);
  }
}

.h-modal-url-wrap {
  word-wrap: break-word;
}
