/*----------------------------------------------------------------------------
 *
 * FIGURE
 *
 *---------------------------------------------------------------------------*/

.c-figure {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media (--bp-mid) {
    & {
      flex-direction: row;
    }
  }
}

.c-figure__video {
  border: 1px solid var(--gray);
  overflow: hidden;
  width: 100%;
  @media (--bp-mid) {
    & {
      flex: 1 0 63%;
      width: 63%;
    }
  }
}

.c-figure__video-wrapper {
  padding: 56.25% 0 0 0;
  width: 100%;
  position: relative;
}

.c-figure__video-source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-figure__caption {
  font-size: var(--gamma);
  font-family: var(--font-family-display);
  font-weight: 600;
  line-height: 1.1;
  box-sizing: border-box;
  margin: 1rem 0;
  position: relative;
  padding: 0 0 3rem 0;
  text-align: center;
  max-width: 20em;
  @media (--bp-mid) {
    & {
        padding: 0 0 0 3rem;
        flex: 1 1 37%;
        text-align: left;
    }
  }
}

.c-figure__caption::before {
  content: "";
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -1rem;
  border-top: 1rem solid var(--gray);
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  @media (--bp-mid) {
    & {
      left: 0;
      top: 50%;
      margin-left: 0;
      margin-top: -1rem;
      border-top: 1rem solid transparent;
      border-left: 1rem solid transparent;
      border-right: 1rem solid var(--gray);
      border-bottom: 1rem solid transparent;
    }
  }
}

.c-figure__listing {
  list-style: none;
  margin: 0 0 1rem 0;
  padding-right: 1rem;
  flex: 0 1 33%;
  box-sizing: border-box;
  @media (--bp-mid) {
    & {
      flex: 1 1 25%;
    }
  }
}

.c-figure__subhed {
  font-size: var(--base);
  font-family: var(--font-family-display);
  font-weight: 700;
  margin: 0 0 1rem;
  line-height: 1.1;
}

.c-figure__sublisting {
  list-style: none;
  margin: 0;
  padding: 0;
}
