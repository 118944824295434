/*----------------------------------------------------------------------------
 *
 * ANNOUNCEMENT
 *
 *---------------------------------------------------------------------------*/

.c-announcement {
  background: #008470;
  color: var(--white);
  padding: 0 2rem;
  text-align: center;
  @media (--bp-mid) {
    & {
      font-size: var(--zeta);
    }
  }
  @media (--bp-mega) {
    & {
      text-align: left;
      background:
        linear-gradient(90deg, #ffffff 0%, #ffffff 49.975%, #008470 50%, #008470 100%);
      padding: 0;
    }
  }
}

.c-announcement [class^="hed"] {
  color: var(--white);
  margin-top: 0;
}

.c-announcement a {
  color: var(--light-gray);
  font-weight: 600;
}

.c-announcement a:hover,
.c-announcement a:active,
.c-announcement a:visited {
  color: var(--white);
  font-weight: 600;
}

.c-announcement__inner {
  margin: 0 auto;
  max-width: var(--content-width);
  background: #008470;
  padding: 1.5rem 0;
}

.c-announcement__inner p {
  margin: 0;
  @media (--bp-mega) {
    & {
      padding-left: 2rem;
    }
  }
}
