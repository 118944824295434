/*----------------------------------------------------------------------------
 *
 * CTA Sidebar
 * Appropriate for multi-column layouts in the landing page main content area.
 *
 *---------------------------------------------------------------------------*/

.c-cta-sidebar {
  display: flex;
  flex-direction: column;
  border: .125rem solid #ebebeb;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: 2rem;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }
  @media (--bp-min) {
    & {
      margin-top: -1rem;
      margin-bottom: -1.125rem;
    }
  }
}
