/* ----------------------------------------------------------------------------
 *
 * Component: Publications Index Styles
 * Contains/constrains/centers the content for curriculum
 * selection page. Matches Registration App pages.
 *
 * ------------------------------------------------------------------------- */

.c-index {
  font-family: var(--font-family-text-alt);
  margin: auto auto 5rem;
  padding: 2.625rem 1rem 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 42.8rem;
}

.c-index * {
  box-sizing: border-box;
}

/*
  -- hed
  Presumably an H1. Appears over the textured
  motif that signifies the Registration App screens.
*/

.c-index__hed {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--alpha);
  margin: 0 auto 2.625rem;
  line-height: 2.75rem;
  text-align: center;
  color: var(--white);
}

/*
  -- inner
  Inner container for publication options.
  Appears as a “floating” white box.
*/

.c-index__inner {
  margin: 2.5rem auto;
  background-color: var(--white);
  padding: 1rem 1.5rem 2.75rem;
  box-shadow: .25rem .3rem .5rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
}

/*
  -- Subhed
  Presumably an H2. Intentionally has the same appearance as
  a Registration Page Legend.
*/

.c-index__subhed {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--gamma);
  color: var(--text);
  line-height: 1.2;
}

/*
  -- Info groups
  Laid out identically to the form groups
  found in the Registration App.
*/

.c-index__infogroup {
  margin: 1rem auto;
  width: 100%;
  max-width: 23.5rem;
  clear: left; /* I know! A clearing floats flashback! Clears the floated legend element when it’s present. */
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 1.25rem;
  }
}

/*
  -- Subject groups
  Styles for subject groupings of curriculum options.
*/

.c-index__subjectgroup {
  margin: 2rem auto 1rem;
  border: .125rem solid var(--our-primary-yellow);
  border-radius: .4rem;
  padding: .5rem 1rem;
  max-width: 30.5rem;
  position: relative;
}

.c-index__subjectlabel {
  position: absolute;
  left: 1.25rem;
  top: -.575em;
  margin: 0;
  padding: 0 .25rem;
  line-height: 1.1rem;
  background: var(--white);
  text-transform: uppercase;
  font-size: var(--delta);
  letter-spacing: .03em;
}

/*
  -- note style
  One step smaller than default text size.
*/

.c-index__note {
  display: inline-block;
  color: var(--text);
  font-size: var(--epsilon);
  font-weight: 400;
  line-height: 1.2;
}

.c-index__note a {
  color: var(--accent);
  text-decoration-color: var(--accent-large);
  &:link,
  &:visited {
    color: var(--accent);
    text-decoration-color: var(--accent-large);
  }
  &:hover,
  &:active,
  &:focus {
    color: var(--accent-large);
    text-decoration-color: var(--accent-large);
  }
}


/*
  -- Curriculum Choice
  Conjoined Text + "Go" CTA
*/

.c-index__curriculum-choice {
  border-color: var(--our-primary-yellow);
  background-color: var(--light-gray);
  border-radius: .4rem;
  color: var(--text);
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  @media (--bp-min-xxx) {
    & {
      align-items: baseline;;
    }
  }
}

.c-index__curriculum-choice:hover {
  color: var(--accent);
}

.c-index__curriculum-choice::after {
  content: "";
  width: 0;
  height: 0;
  border-top: transparent solid .5rem;
  border-right: transparent solid .75rem;
  border-bottom: transparent solid .5rem;
  border-left: var(--text) solid .75rem;
  margin-left: auto;
  align-self: center;
}

.c-index__curriculum-choice:hover::after {
  border-left-color: var(--accent-large);
}

.c-index__curriculum-title {
  padding: .75rem 0;
  margin-right: .75em;
  flex: 0 1 auto;
  line-height: 1.3rem;
  @media (--bp-min-xxx) {
    & {
      white-space: nowrap;
      flex: 0 0 0;
    }
  }
}

.c-index__curriculum-edition {
  font-size: var(--zeta);
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 700;
  padding: 0 1rem 0 .5rem;
  line-height: 1.3rem;
  color: var(--accent);
  white-space: nowrap;
  display: inline-block;
  vertical-align: baseline;
}

.c-index__edition-separator {
  color: var(--our-primary-yellow);
  border-style: dashed;
}

/*
  -- Subject group helper class
    For aligning elements outside `.c-index-subjectgroup' with
    that subject group.
*/

.c-index .for-subjectgroup {
  max-width: 30.5rem;
  margin-left: auto;
  margin-right: auto;
}
