/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL NAV
 * Included in this document: Variables, Base, Styles
 *
 * The instance of the global nav embedded within
 * the reg app is canonical.
 * (https://github.com/openupresources/curriculum-gateway)
 *
 *-------------------------------------*/

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL NAV -- ROBOTO FONTS
 *
 *-------------------------------------*/

 /* Avoid loading unnecessary font weights! */
 /* Always necessary: */
    /* Roboto 500 for top and second-level list items. */
 /* Sometimes necessary: */
    /* Roboto 400 for third-level list items in narrow viewports and global notifications. */
 /* Only necessary in the Curriculum Gateway/Reg App: */
    /* Roboto 700 for “Create New Account” on login page. */

 /* If Google Fonts are already imported into the app, include "&family=Roboto:ital,wght@0,400;0,500;1,700" as necessary. */
 /* If Google Fonts are not already imported, uncomment the following import statement or (better) add
 the equivalent to the <head> of the app. */
 /* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,700&display=swap'); */

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL NAV -- VARIABLES
 *
 *-------------------------------------*/

/* Color palette */

:root {

  /* Colors with "-our-" in their name are sourced from the OUR brand guidelines */

--nav-global-our-primary-orange:       rgb(252,76,2);    /* #FC4C02 */
--nav-global-our-globalnav-orange:     rgb(241,89,42);   /* #F1592A  - a less bright orange */
--nav-global-our-primary-yellow:       rgb(255,184,28);  /* #FFB81C */
--nav-global-our-primary-gray:         rgb(110,98,89);   /* #6E6259 */
--nav-global-our-primary-dark-gray:    rgb(69,53,54);    /* #453536 */
--nav-global-white:                    white;
--nav-global-light-gray:               rgb(241,241,241);

/*
  Typographic sizes

  Font sizes based on minor third (1.2) http://www.modularscale.com/?1&em&1.2

*/

--nav-global-font-base-min: 16px;
--nav-global-font-base-max: 20px;
--nav-global-fluid-em:      calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
--nav-global-delta:         1.2em;
--nav-global-epsilon:       .833em;
--nav-global-zeta:          .8em;

}

/* Custom Media Declarations for Breakpoints */

@custom-media --nav-global-bp-min-minus (max-width: 536px);
@custom-media --nav-global-bp-min (min-width: 537px);
@custom-media --nav-global-bp-mid-minus (max-width: 1023px);
@custom-media --nav-global-bp-mid (min-width: 1024px);
@custom-media --nav-global-bp-max-minus (max-width: 1319px);
@custom-media --nav-global-bp-max (min-width: 1320px);

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL NAV -- BASE
 *
 *-------------------------------------*/

.c-nav-global {
   font-size: var(--nav-global-font-base-min);
   font-family: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif;
   font-weight: 500;
   text-rendering: optimizelegibility; /* Can cause problems in some versions of Android */
     text-size-adjust: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-display: swap; /* https://www.zachleat.com/web/comprehensive-webfonts/#font-display */
   line-height: 1.45;

   /* Fluid from 16px to 20px between breakpoints */

   @media (--nav-global-bp-min) {
     & {
       font-size: var(--nav-global-font-base-min); /* 16px at 320px */ /* px fallback for browsers that don’t get calc */
       font-size: calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
     }
   }

   @media (--nav-global-bp-max) {
     & {
       font-size: var(--nav-global-font-base-max); /* 20px at 1320px */
     }
   }
}

.c-nav-global * {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL NAV -- STYLES
 *
 *-------------------------------------*/

.c-nav-global {
  @media (--nav-global-bp-mid) {
    & {
      z-index: 8675309;
      width: 100%;
    }
  }
}

.c-nav-global__wrapper {
  padding: .75em 1em;
  background: var(--nav-global-our-globalnav-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position:relative;
  @media (--nav-global-bp-mid) {
    & {
      padding: 0 .25em 0 1em;
    }
  }
}

.c-nav-global__logolink {
  display: block;
  @media (--nav-global-bp-mid) {
    & {
      padding: 0.83125em 0;
    }
  }
}

.c-nav-global__logoimg {
  display: block;
  height: 2em;
  width: auto;
}

.c-nav-global__menu {
  display:inline-block;
  text-decoration: none;
  color: var(--nav-global-white);
  border: 3px var(--nav-global-our-primary-yellow) solid; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying.  */
  border-radius: calc(1.25 * var(--nav-global-fluid-em));
  padding: calc(.5 * var(--nav-global-fluid-em)) calc(var(--nav-global-fluid-em));
  text-transform: uppercase;
  line-height: calc(var(--nav-global-fluid-em));
  width: calc(5 * var(--nav-global-fluid-em));
  text-align:center;
  font-size: var(--nav-global-epsilon);
  font-weight: 500;
  @media (--nav-global-bp-mid) {
    & {
      display: none;
    }
  }
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: var(--nav-global-white);
  }
}

.c-nav-global__nav {
  @media (--nav-global-bp-mid-minus) {
    & {
      background: var(--nav-global-our-globalnav-orange);
      padding: .75em 1em;
      text-align: right;
      position: fixed;
      overflow-y: scroll;
      z-index: 8675309;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s, visibility 0s 0.5s;

      &:target:not([data-skip-targeting]), &[data-visible] {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s;
      }
    }
  }
}

.c-nav-global__list {
  list-style: none;
  margin: 1em auto 0;
  padding: 2em 1em 2em;
  text-align: center;
  position: static;
  @media (--nav-global-bp-mid) {
    & {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.c-nav-global__list {
  /* Annoying necessity - override of OUR wordpress theme defaults for ul */
  position: static;
}

.c-nav-global__listitem {
  list-style: none;
  hyphens: none;
  margin: 0;
  padding: .65625em 0 0;
  line-height: 1.25;
  @media (--nav-global-bp-mid) {
    & {
      padding: 1.15625em 0;
    }
  }
}

/* Annoying necessity - override of OUR wordpress theme defaults for ul li */
.c-nav-global__listitem::before {
  content: normal;
}

.c-nav-global__label {
  color: var(--nav-global-white);
  font-size: var(--nav-global-delta);
  display: inline-block;
  @media (--nav-global-bp-mid) {
    & {
      font-size: var(--nav-global-epsilon);
    }
  }
}

.c-nav-global__link,
.c-nav-global__link:link,
.c-nav-global__link:visited,
.c-nav-global__link:active {
  font-size: var(--nav-global-delta);
  font-weight: 500;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--nav-global-white);
  padding: calc(.5 * var(--nav-global-fluid-em));
  transition: none;
  @media (--nav-global-bp-mid) {
    & {
      font-size: var(--nav-global-epsilon);
      padding: 0 var(--nav-global-font-base-max);
    }
  }
  @media (--nav-global-bp-mid) {
    &::before,
    &::after {
      display: block;
      height: 3px; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying.  */
      width: 100%;
      content: "";
      position: relative;
      top: 2px;
    }
  }
  @media (--nav-global-bp-mid-minus) {
    &::before,
    &::after {
      position: absolute;
      bottom: calc(.25 * var(--nav-global-fluid-em));
      left: 0;
      right: 0;
      width: auto;
    }
  }
  &:hover {
    color: var(--nav-global-white);
    text-decoration: none;
  }
  @media (--nav-global-bp-mid) {
    &:hover::after,
    &:focus::after {
      background: var(--nav-global-our-primary-yellow);
    }
  }
}

/* 700 weight only required in the Reg App for formatting "Create New Account" */
.c-nav-global__label + .c-nav-global__link,
.c-nav-global__label + .c-nav-global__link:link,
.c-nav-global__label + .c-nav-global__link:visited,
.c-nav-global__label + .c-nav-global__link:active {
  padding-left: 0;
  font-weight: 700;
  &::before,
  &::after {
    display: block;
    height: 3px; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying.  */
    width: 100%;
    content: "";
    position: relative;
    top: 2px;
  }
  &::after {
    background: var(--nav-global-our-primary-yellow);
    opacity: .75;
  }
  &:hover::after {
    opacity: 1;
  }
  @media (--nav-global-bp-mid) {
    &::before,
    &::after {
      top: 0;
    }
  }
}

.c-nav-global__listitem:last-child:not(:only-child) {
  & .c-nav-global__link,
  & .c-nav-global__link:link,
  & .c-nav-global__link:visited,
  & .c-nav-global__link:active {
    @media (--nav-global-bp-mid) {
      & {
        margin-left: var(--nav-global-font-base-max);
        padding-left: calc(2 * var(--nav-global-font-base-max));
        border-left: 1px solid var(--nav-global-white);
      }
    }
  }
}
.c-nav-global__notification {
  padding: var(--nav-global-zeta) 3em;
  background-color: orange;
  text-align: center;
}

.c-nav-global__notification p {
  font-size: var(--nav-global-zeta);
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}

.c-nav-global__notification a {
  color: var(--nav-global-our-primary-dark-gray);
  border: none;
  text-decoration: underline;
  white-space: nowrap;
}

.c-nav-global__helper-nowrap {
  white-space: nowrap;
}
