/*----------------------------------------------------------------------------
 *
 * LAYOUT
 *
 *---------------------------------------------------------------------------*/

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-header {

}

.l-header_bookworms {
  flex: 1 0 auto;
  position: relative;
}

.l-header_el-k8 {
  flex: 1 0 auto;
  position: relative;
}

.l-header_el-skills-block-2024 {
  flex: 1 0 auto;
  position: relative;
}

.l-banner__inner-columns {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (--bp-mid) {
    & {
      flex-direction: row;
      align-items: flex-end;
    }
  }
}

.l-banner__inner-left-column {
  @media (--bp-mid) {
    & {
        flex: 1 0 45%;
        width: 45%;
        text-align: left;
        margin-right: 2rem;
    }
  }
}

.l-banner__inner-left-column p {
  margin: 1rem 0;
  &:first-child {
    margin-top: 0;
  }
  @media (--bp-mid) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.l-banner__inner-right-column {
  width: 100%;
  @media (--bp-mid) {
    & {
      flex: 1 0 45%;
      width: 45%;
    }
  }
}

.l-main {
  padding: 1rem 1rem 2rem;
}

.l-content {
  width: 100%;
  max-width: var(--content-width);
  margin: 1rem auto;
}

.l-content_golden-columns {
  @media (--bp-min) {
    & {
      margin: 2rem auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  @media (--bp-mid) {
    & {
      align-items: stretch;
      margin: 3rem auto;
    }
  }
}

.l-content_golden-columns .c-button {
  margin-bottom: 0;
}

.l-content__golden-column-a {
  & > *:first-child {
    margin-top: 0;
  }
  @media (--bp-min) {
    & {
      flex: 1 0 45%;
      width: 45%;
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
    }
    & > *:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
}

.l-content__golden-column-b {
  @media (--bp-min) {
    & {
      flex: 1 0 45%;
      width: 45%;
    }
    & > *:first-child {
      margin-top: 0;
    }
  }
}

.l-footer {
  margin-top: auto;
  background: var(--our-primary-dark-gray);
}

.l-list-of-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.l-list-of-buttons li {
  margin-bottom: 0;
}

.is-hidden {
  display: none;
}

.l-index-wrapper {
  background: var(--accent-large);
  background:
    url('forms/texture-regform-white15.svg'),
    linear-gradient(var(--accent-large) 0%, var(--accent-large) 18.75rem, var(--white) 18.75rem,  var(--white) 100%);
  min-height: 20rem;
  background-size:
    67rem,
    100%;
  background-repeat:
    repeat-x,
    no-repeat;
  background-blend-mode:
    overlay,
    normal;
}
