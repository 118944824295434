/*----------------------------------------------------------------------------
 *
 * TYPOGRAPHY
 *
 *---------------------------------------------------------------------------*/

html {
  font-size: var(--rem);
  font-family: var(--font-family-text);
  font-style: normal;
  font-weight: 400;
  text-size-adjust: none;

  /* Fluid type size from 16px to 20px between --bp-min and --bp-max breakpoints */

  @media (--bp-min) {
    & {
      font-size: 1em; /* 16px at 320px */
      font-size: calc(1em + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
    }
  }

  @media (--bp-max) {
    & {
      font-size: calc(20em/16); /* 20px at 1320px */
    }
  }
}

body {
  line-height: 1.35;
  color: var(--text);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Fluid line-height from 1.35 to 1.45 between $bp-min and $bp-max breakpoints */

  @media (--bp-min) {
    & {
      line-height: 1.35; /* 1.35 at 320px */
      line-height: calc(1.35em + (1.45 - 1.35) * ((100vw - 320px)/(1320 - 320)));
    }
  }

  @media (--bp-max) {
    & {
      line-height: 1.45; /* 1.45 at 1320px */
    }
  }
}

a {
  color: var(--text);
  &:hover {
     color: var(--our-globalnav-orange);
     text-decoration-color: color(var(--our-primary-orange) a(25%));
  }
}

strong, b {
  font-weight: 600;
}

/*
     HEADING and TEXT SIZES
*/

h1,h2,h3,h4,h5,h6 {
  hyphens: none;
}

.hed-alpha,
.hed-beta,
.hed-gamma,
.hed-delta,
.hed-epsilon,
.hed-zeta,
[class|=hed-sequence] {
  font-weight: normal;
  font-style: normal;
  display: block;
  margin-bottom: .5em;
  line-height: 1.1;
  page-break-after: avoid;
  hyphens: none;
}

.hed-alpha,
.hed-beta,
.hed-gamma,
[class|=hed-sequence] {
  margin-top: 3rem;
}

.hed-delta,
.hed-epsilon,
.hed-zeta {
  margin-top: 2rem;
}

[class*=hed-]:not(span) + [class*=hed-]:not(span) {
  margin-top: 1rem;
}

.hed-alpha {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--alpha);
  color: var(--deep);
}

.hed-beta {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--beta);
  color: var(--primary);
}

.hed-gamma {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--gamma);
  color: var(--text);
}

.hed-delta {
  font-family: var(--font-family-display);
  font-weight: 600;
  font-size: var(--delta);
  color: var(--text);
  margin-bottom: 1rem;
}

.hed-epsilon {
  font-size: var(--epsilon);
  font-weight: 600;
  color: var(--text);
}

.hed-zeta {
  font-size: var(--zeta);
  font-weight: 600;
  color: var(--text);
}

.text-epsilon {
  font-size: var(--epsilon);
}

.text-zeta {
  font-size: var(--zeta);
}

.is-text-color {
  color: var(--text);
}

/*
     PARAGRAPHS
*/

p {
  margin: 0 0 1rem;
  orphans: 2;
  widows: 2;
}

p:last-child {
  margin-bottom: 0;
}

/*
     LISTS AND VARIATIONS
*/

ul {
  margin: 1rem 0 1rem 1rem;
  padding: 0;
  orphans: 2;
  widows: 2;
}

ul ul,
ol ol {
  margin-top: .5rem;
}

li > p + ul {
  margin-top: -.5rem;
}

ol {
  margin: 1rem 0 1rem 1rem;
  padding: 0;
  orphans: 2;
  widows: 2;
}

li {
  margin-bottom: .5rem;
}

li:last-child {
  margin-bottom: 0;
}

/* Helpers */

.h-hide-cms-content-during-development { /* @TODO delete rule after development */
  display: none;
}

@media (--bp-min) {
  .h-nobr {
      white-space: nowrap;
  }
}

.h-url-wrap { /* allow URLs displayed inline to wrap */
  word-wrap: break-word;
}

.h-show-inline-bp-min {
  display: none;
  @media (--bp-min) {
    & {
      display: inline;
    }
  }
}

.h-flush-top {
  margin-top: 0;
  padding-top: 0;
}

.h-flush-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}
