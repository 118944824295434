/*----------------------------------------------------------------------------
 *
 * Sizes
 *
 *---------------------------------------------------------------------------*/

/* Font and Foundational Modular Sizes */

:root {
  --alpha:            2.4rem;                /* Eg. Banners and Text Treated Like Large Graphics */
  --beta:             1.784rem;              /* Eg. H2ish Headings */
  --gamma:            1.2rem;              /* Eg. H3ish Headings */
  --rem:              100%;                  /* Eg. Foundation for all type, margin, and padding sizes */
  --delta:            1rem;                  /* Eg. H4ish Headings */
  --base:             1rem;                  /* Eg. Body font */
  --epsilon:          .9rem;                 /* Eg. Standards tags */
  --zeta:             .833rem;               /* DO NOT EDIT — Used in curriculum nav and breadcrumb nav */
  --eta:              .75rem;                /* Reserved for fine print and informationally dense elements like tables */

}

/* Content Area Related Sizes */

:root {
  --measure: 40rem;
  --content-width: 48rem;
}

/* Viewport Width Variables */

:root {

  --bp-nano-minus:   319px;
  --bp-nano:         320px;
  --bp-micro-minus:  410px;
  --bp-micro:        411px;
  --bp-min-minus:    536px;
  --bp-min:          537px;
  --bp-mid-minus:    767px;
  --bp-mid:          768px;
  --bp-mega-minus:   1023px;
  --bp-mega:         1024px;
  --bp-max-minus:    1319px;
  --bp-max:          1320px

}

/* Custom Media Declarations for Breakpoints */

@custom-media --bp-nano-minus (max-width: 319px);
@custom-media --bp-nano (min-width: 320px);
@custom-media --bp-micro-minus (max-width: 410px);
@custom-media --bp-micro (min-width: 411px);
@custom-media --bp-min-minus (max-width: 536px);
@custom-media --bp-min (min-width: 537px);
@custom-media --bp-mid-minus (max-width: 767px);
@custom-media --bp-mid (min-width: 768px);
@custom-media --bp-mega-minus (max-width: 1023px);
@custom-media --bp-mega (min-width: 1024px);
@custom-media --bp-max-minus (max-width: 1319px);
@custom-media --bp-max (min-width: 1320px);
