/*----------------------------------------------------------------------------
 *
 * BANNER
 *
 *---------------------------------------------------------------------------*/

.c-banner {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  color: var(--white);
  padding: 4rem 1rem;
}

.c-banner_odell-hs-ela {
  background-color: rgba(47,55,118,0.81); /* #2f3776*/
  background-image:
    linear-gradient(90deg, rgba(47,55,118,0.81) 0%, rgba(47,55,118,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/odell-hs-ela_background50Q.jpg');
  background-size:
    cover,
    cover;
  padding: 4rem 1rem 6rem; /* Beefy padding since there is so little in the banner. */
}

.c-banner_our6-8math {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/our6-8math_background50Q.jpg');
  background-size:
    cover,
    cover;
}

.c-banner_our-hs-math {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/our-hs-math-aga_background25Q.jpg');
  background-size:
    cover,
    cover;
}

.c-banner_our-hs-math-pilot-pl {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/our-hs-math-MVPPilot_LandingPage_Image50Q.jpg');
  background-size:
    cover,
    cover;
}

.c-banner_our-hs-math-nc {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/our-hs-math-nc_background25Q.jpg');
  background-size:
    cover,
    cover;
}

.c-banner_our-k5-math {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/ourmath-k5_background_optimized.svg');
  background-size:
    cover,
    cover;
}

.c-banner_k8-math-pilot-pl {
  background-color: rgba(21,11,73,0.81); /* #150B49 */
  background-image:
    linear-gradient(90deg, rgba(21,11,73,0.81) 0%, rgba(21,11,73,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/ourmath-k5_background_optimized.svg');
  background-size:
    cover,
    cover;
}

.c-banner_bookworms {
  background-color: rgba(17,65,65,0.81); /* #114141 */
  background-image:
    linear-gradient(90deg, rgba(17,65,65,0.81) 0%, rgba(17,65,65,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/bookworms_background50Q_2022.jpg');
  background-size:
    cover,
    cover;
}

.c-banner_bookworms-pilot-pl {
  background-color: rgba(17,65,65,0.81); /* #114141 */
  background-image:
    linear-gradient(90deg, rgba(17,65,65,0.81) 0%, rgba(17,65,65,0.81) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image */
    url('publications/bookworms-pilot-pl-landing-page-background50Q.jpg');
  background-position: center;
  background-size:
    cover,
    cover;
}

.c-banner_bw-di {
  background-color: var(--wormsy);
  background-image: url('publications/bw-pl_background50Q.jpg');
  background-size: cover;
  align-items: center;
  color: var(--text);
  box-shadow:
    inset 0 .125rem 0 0 var(--wormsy),
    inset 0 -0.125rem 0 0 var(--wormsy);
}

.c-banner_bw-pl {
  background-color: var(--wormsy);
  background-image: url('publications/bw-pl_background50Q.jpg');
  background-size: cover;
  align-items: center;
  color: var(--text);
  box-shadow:
    inset 0 .125rem 0 0 var(--wormsy),
    inset 0 -0.125rem 0 0 var(--wormsy);
}

.c-banner_el-k8 {
  background-color: var(--text, rgb(69,53,54));
  background-image:
    linear-gradient(90deg, rgba(69,53,54,0.85) 0%, rgba(69,53,54,0.85) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image - Colors in gradient are RGBA equivalents of var(--text) + 85% Alpha */
    url('publications/el-k8_background50Q.jpg');
  background-size:
    cover,
    cover;
  background-blend-mode:
    normal,
    normal;
}

.c-banner_el-pilot-pl {
  background-color: var(--text, rgb(69,53,54));
  background-image:
    linear-gradient(90deg, rgba(69,53,54,0.85) 0%, rgba(69,53,54,0.85) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image - Colors in gradient are RGBA equivalents of var(--text) + 85% Alpha */
    url('publications/el-k8_background50Q.jpg');
  background-size:
    cover,
    cover;
  background-blend-mode:
    normal,
    normal;
}

.c-banner_el-skills-block-2024 {
  background-color: var(--text, rgb(69,53,54));
  background-image:
    linear-gradient(90deg, rgba(69,53,54,0.85) 0%, rgba(69,53,54,0.85) 100%), /* Set as a flat, non-changing gradient in order to layer this color like an image - Colors in gradient are RGBA equivalents of var(--text) + 85% Alpha */
    url('publications/el-skills-block-2024-background.jpg');
  background-size:
    cover,
    cover;
  background-blend-mode:
    normal,
    normal;
}

.c-banner [class^="hed"] {
  color: var(--white);
  margin-top: 0;
}

.c-banner__inner {
  margin: 0 auto;
  max-width: var(--content-width);
}

.c-banner__dek {
  font-family: var(--font-family-display);
  font-weight: 600;
  font-size: var(--gamma);
  line-height: 1.1;
}

.c-banner__dek a {
  color: var(--white);
  text-decoration: none;
  border-bottom: 1px solid var(--light-gray);
}

.c-banner__dek a:hover {
  border-color: var(--white);
}

.c-banner__hed {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--alpha);
  line-height: 1;
}

.c-banner_bw-pl .c-banner__hed {
  color: var(--wormsy-aa);
}

.c-banner__lede a,
.c-banner__fineprint a {
  color: var(--white);
  text-decoration: none;
  border-bottom: 1px solid var(--light-gray);
}

.c-banner__lede a:hover,
.c-banner__fineprint a:hover {
  border-color: var(--white);
}

.c-banner__form {
}

.c-banner__chooser-group:first-of-type {
  margin-top: 3.5rem;
}

.c-banner__chooser-group.is-disabled,
.c-banner__acknowledgment.is-disabled {
  opacity: .4;
}

.c-banner__chooser-group.is-disabled .c-button_outline-reverse:focus,
.c-banner__chooser-group.is-disabled .c-button_outline-reverse:hover {
  background-color: transparent;
  border-color: var(--light-gray);
  color: var(--light-gray);
  cursor: default;
}

/* Container for a checkbox + label pair */
.c-banner__acknowledgment {
  margin-top: 1rem;
  overflow: visible;
  display: flex;
  align-items: baseline;
  gap: 0.5em;
}

.c-banner__label {
  overflow: hidden;
}

/* Off-screen radio button styles and contexts */

.c-banner__radio {
  position: absolute;
  left: -10rem;
}

.c-banner__radio:focus-visible + .c-button {
  box-shadow:
    inset 0 0 0 0.15em var(--aa-orange),
    inset 0 0 0 0.3em #fff;
}

.c-banner__radio:checked + .c-button {
  background: var(--white);
  color: var(--accent);
}

/* Checkbox styles and contexts */

.c-banner__checkbox:focus-visible + .c-banner__label {
  box-shadow:
    0 0 0 0.15em #fff,
    0 0 0 0.3em var(--aa-orange);
}

/* Submit button styles and contexts */

.c-banner__submit {
  margin-top: 2rem;
  clear: both;
}

.c-banner__submit > a:focus-visible {
  box-shadow:
    inset 0 0 0 0.15em var(--aa-orange),
    inset 0 0 0 0.3em #fff;
}

.c-banner__fineprint {
  margin-top: 1rem;
  font-size: .75rem;
}

/* The following containing elements are used instead of a form to contain the grade choices when the options may simply be links instead of input fields. */

.c-banner__course-choices .c-button {
  padding-left: 0;
  padding-right: 0;
  margin: .5rem auto;
  display: block;
  width: 9rem;
  box-sizing: border-box;
}

.c-banner__course-choices .c-button:first-child {
  margin-top: 1.25rem;
}

@media (min-width: 375px) {

  .c-banner__course-choices {
    --max-width: 28.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: var(--max-width); /* constrain the width of the option container, preventing awkward wraps */
  }

  .c-banner__course-choices .c-button,
  .c-banner__course-choices .c-button:first-child {
    margin-top: .25rem;
    flex: 0 0 8rem;
    margin-left: 0;
    margin-right: .75rem;
  }

}

.c-banner__cta {
  font-family: var(--font-family-display);
  font-weight: 600;
  color: var(--white);
  margin-top: 2rem;
  font-size: var(--gamma);
}

.c-banner__cta_alt {
  font-family: var(--font-family-text);
  font-size: var(--epsilon);
  font-style: italic;
}

@media (min-width: 375px) {
  .c-banner__cta br {
    display: none;
  }
}

.c-banner__img {
  display:  block;
  max-width: 80%;
  margin-left:  auto;
  margin-right: auto;
}

.c-banner__video-wrapper {
  padding: 56.25% 0 0 0;
  width: 100%;
  position: relative;
  box-shadow:
    .125rem .125rem .125rem 0 rgba(0, 0, 0, .125),
    1px 1px 0 0 rgba(0, 0, 0, .25);
  z-index: 10;
}

.c-banner__video-source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
