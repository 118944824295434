/* ----------------------------------------------------------------------------
 *
 * BUTTONS
 *
 * ------------------------------------------------------------------------- */

.c-button {
  background-color: var(--accent);
  border: 0.0625rem solid var(--accent);
  border-radius: 0.25rem;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  height: 2.375rem;
  line-height: 2.375rem;
  padding: 0 1.875rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  appearance: none;
  margin: .25rem .5rem .25rem 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  @media (--bp-mid) {
    & {
      font-size: var(--epsilon);
    }
  }
}

.c-button_small {
  height: 2.375em;
  line-height: 2.375em;
  padding: 0 1.875em;
  font-size: var(--eta);
}

.c-button:first-of-type {
  margin-left: 0;
}

.c-button:focus,
.c-button:hover {
  background-color: var(--accent-large);
  border-color: var(--accent-large);
  color: #fff;
  outline: 0;
}

.c-button[disabled] {
  cursor: default;
  opacity: .4;
}

.c-button[disabled]:focus,
.c-button[disabled]:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}

.c-button_beefy {
  padding: .125rem 2.5rem;
  height: auto;
}

.c-button_wormsy {
  background-color: var(--wormsy-aa);
  border-color: var(--wormsy-aa);
}

.c-button_wormsy:focus,
.c-button_wormsy:hover {
  background-color: var(--wormsy-aa-large);
  border-color: var(--wormsy-aa-large);
}

.c-button_wormsy-grade {
  min-width: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.c-button_reverse {
  background-color: var(--white);
  border: 0.0625rem solid var(--white);
  color: var(--accent);
}

.c-button_reverse:focus,
.c-button_reverse:hover {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--accent-large);
}

.c-button_outline {
  background-color: transparent;
  color: var(--accent);
}

.c-button_outline:focus,
.c-button_outline:hover {
  background-color: transparent;
  border-color: var(--accent-large);
  color: var(--accent-large);
}

.c-button-outline[disabled]:focus,
.c-button-outline[disabled]:hover {
  border-color: inherit;
  color: var(--accent);
}

.c-button_outline-reverse {
  background-color: transparent;
  border-color: var(--light-gray);
  color: var(--light-gray);
}

.c-button_outline-reverse:focus,
.c-button_outline-reverse:hover {
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
}

.c-button-outline-reverse[disabled]:focus,
.c-button-outline-reverse[disabled]:hover {
  border-color: inherit;
  color: var(--light-gray);
}

.c-button_clear {
  background-color: transparent;
  border-color: transparent;
  color: var(--accent);
}

.c-button_clear:focus,
.c-button_clear:hover {
  background-color: transparent;
  border-color: transparent;
  color: var(--accent-large);
}

.c-button_clear[disabled]:focus,
.c-button_clear[disabled]:hover {
  color: var(--accent);
}

.c-button_clear-reverse {
  background-color: transparent;
  border-color: transparent;
  color: var(--light-gray);
}

.c-button_clear-reverse:focus,
.c-button_clear-reverse:hover {
  background-color: transparent;
  border-color: transparent;
  color: var(--white);
}

.c-button_clear-reverse[disabled]:focus,
.c-button_clear-reverse[disabled]:hover {
  color: var(--light-gray);
}

.c-button .c-icon {
  margin-right: .5ch;
  font-size: 1.2em;
  margin-left: -1ch;
}

.c-button.has-icon-before::before,
.c-button.has-icon-after::after {
  font-size: 1.1em;
  line-height: 0;
}
