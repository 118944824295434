/*----------------------------------------------------------------------------
 *
 * Fonts
 *
 *---------------------------------------------------------------------------*/

:root {
  --font-family-text: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif; /* Default text, Global Nav, Curriculum Nav, Buttons… 400,500,700 weight */
  --font-family-text-alt: 'Source Sans Pro', sans-serif; /* Default text for index. 400, 600, 700 weight */
  --font-family-display: agenda-one, sans-serif; /* Headings — 600, 700 */
}
