/* Make content visible screen readers only when not focused or active */
/* "sr" meaning "screen-reader". Learn more: https://css-tricks.com/inclusively-hidden/ */

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: none;
  box-shadow:
    0 0 0 0.15em #fff,
    0 0 0 0.3em var(--aa-orange);
}

@media (forced-colors: active) {
  *:focus-visible {
    outline: 0.2em solid;
  }
}
