/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL FOOTER
 * Included in this document: Variables, Base, Styles
 *
 * The instance of the global footer embedded within
 * the reg app is considered canonical.
 *
 *-------------------------------------*/

/*--------------------------------------
*
* COMPONENT: OUR GLOBAL FOOTER -- ROBOTO FONTS
*
*-------------------------------------*/

/* If Google Fonts are already imported into the app, append "Roboto:500" if necessary. */
/* If Google Fonts are not already imported, uncomment the following import statement or (better) add the equivalent to the <head> of the app. */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:500&subset=latin'); */

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL FOOTER -- VARIABLES
 *
 *-------------------------------------*/

/* Color palette */

:root {

/* Colors with "-our-" in their name are sourced from the OUR brand guidelines */

--footer-our-primary-dark-gray:    rgb(69,53,54);    /* #453536 */
--footer-white:                    #ffffff;

/*
  Typographic sizes

  Font sizes based on minor third (1.2) http://www.modularscale.com/?1&em&1.2

*/

--footer-font-base-min: 16px;
--footer-font-base-max: 20px;
--footer-fluid-em:      calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
--footer-zeta:          .833em;
--footer-eta:           .75em;

}

/* Custom Media Declarations for Breakpoints */

@custom-media --footer-bp-nano-minus (max-width: 319px);
@custom-media --footer-bp-nano (min-width: 320px);
@custom-media --footer-bp-micro-minus (max-width: 410px);
@custom-media --footer-bp-micro (min-width: 411px);
@custom-media --footer-bp-min-minus (max-width: 536px);
@custom-media --footer-bp-min (min-width: 537px);
@custom-media --footer-bp-mid-minus (max-width: 1023px);
@custom-media --footer-bp-mid (min-width: 1024px);
@custom-media --footer-bp-max-minus (max-width: 1319px);
@custom-media --footer-bp-max (min-width: 1320px);

/*--------------------------------------
 *
 * COMPONENT: OUR GLOBAL FOOTER -- BASE
 *
 *-------------------------------------*/

.c-footer {
  font-size: var(--footer-font-base-min);
  font-family: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif;
  font-weight: 500;
  text-rendering: optimizelegibility; /* Can cause problems in some versions of Android */
   text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap; /* https://www.zachleat.com/web/comprehensive-webfonts/#font-display */
  line-height: 1.45;
  margin-top: auto;

  /* Fluid from 16px to 20px between breakpoints */

  @media (--footer-bp-min) {
    & {
     font-size: var(--footer-font-base-min); /* 16px at 320px */ /* px fallback for browsers that don’t get calc */
     font-size: calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
    }
  }

  @media (--footer-bp-max) {
    & {
     font-size: var(--footer-font-base-max); /* 20px at 1320px */
    }
  }
}

.c-footer * {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

/*--------------------------------------
*
* COMPONENT: OUR GLOBAL FOOTER -- OVERRIDES
* Override overarching properties in curriculum gateway theme
*
*-------------------------------------*/

.c-footer li {
  padding-left: inherit;
  line-height: inherit;
}

.c-footer li::before,
.c-footer li::before {
  content: normal;
}

/*--------------------------------------
*
* COMPONENT: OUR GLOBAL FOOTER -- STYLES
*
*-------------------------------------*/

.c-footer {
  padding: .75em 1em 4em; /* Fat padding-bottom accommodates feedback widget */
  color: rgba(100%,100%,100%,.65);
  background: var(--footer-our-primary-dark-gray);
  text-align: center;
  @media (--footer-bp-micro) {
    & {
      text-align: left;
    }
  }
}

.c-footer a {
   color: rgba(100%,100%,100%,.8);
   text-decoration: underline;
}

.c-footer a:hover {
   color: var(--footer-white);
   text-decoration: underline;
}

.c-footer__navs {
  font-size: var(--footer-zeta);
  @media (--footer-bp-micro) {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.c-footer__nav-corporate {
  list-style: none;
  margin: calc(.5 * var(--footer-fluid-em)) 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--footer-bp-micro) {
    & {
      margin-top: 0;
      justify-content: flex-start;
      margin-right: auto;
    }
  }
}

.c-footer__nav-social {
  list-style: none;
  margin: var(--footer-fluid-em) 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--footer-bp-micro) {
    & {
      margin-top: 0;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}

.c-footer__our-logo {
  height: calc(2 * var(--footer-fluid-em));
  width: auto;
  display: block;
  margin: calc(.25 * var(--footer-fluid-em)) auto 0;
  @media (--footer-bp-micro) {
    & {
      position: relative;
      top: calc(.125 * var(--footer-fluid-em));
      margin-right: var(--footer-fluid-em);
      margin-top: 0;
    }
  }
  @media (--footer-bp-max) {
    & {
      top: calc(.125 * var(--footer-font-base-max));
      margin-right: var(--footer-font-base-max);
      height: calc(2 * var(--footer-font-base-max));
    }
  }
}

.c-footer__nav-corporate li,
.c-footer__nav-social li {
  margin-bottom: 0;
}

.c-footer__nav-corporate li + li {
  margin-left: var(--footer-font-base-max);
  @media (--footer-bp-max) {
    & {
      margin-left: var(--footer-font-base-max);
    }
  }
}

.c-footer__nav-social li + li {
  margin-left: var(--footer-font-base-max);
  @media (--footer-bp-max) {
    & {
      margin-left: var(--footer-font-base-max);
    }
  }
}

.c-footer__social-icon {
  height: 1em;
  width: auto;
  display: block;
}

.c-footer__copyrights {
  font-size: var(--footer-eta);
  margin-top: var(--footer-fluid-em);
  padding-top: var(--footer-fluid-em);
  border-top: solid 1px rgba(100%,100%,100%,.35);
  @media (--footer-bp-micro) {
    & {
      margin-top: calc(.75 * var(--footer-fluid-em));
    }
  }
  @media (--footer-bp-max) {
    & {
      padding-top: var(--footer-font-base-max);
      margin-top: calc(.75 * var(--footer-font-base-max));
    }
  }
}

.c-footer__copyrights:only-child,
.c-footer__copyrights:first-child {
  margin-top: 0;
  border: none;
  padding-top: calc(.25 * var(--footer-fluid-em));
  @media (--footer-bp-max) {
    & {
      padding-top: calc(.25 * var(--footer-font-base-max));
    }
  }
}

.c-footer__build {
  display: block;
}
