/*--------------------------------------
 *
 * COMPONENT: CURRICULUM NAV
 *
 *-------------------------------------*/

.c-nav-curriculum {
  font-size: 1rem;
  font-family: var(--font-family-text);
  line-height: 1.45;
  font-weight: 500;
}

.c-nav-curriculum__items {
  list-style: none;
  hyphens: none;
  margin: 0;
  padding: 0 0 0 1rem;
  background: var(--light-gray);
  @media (--bp-nano) {
    & {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    }
  }
}

.c-nav-curriculum__branding {
  position: relative;
  padding: .65625em 0;
  display: flex;
  margin: 0 0 0 0;
  align-self: center;
  @media (--bp-mid) {
    & {
      padding: 0;
    }
  }
}

/* Default styling for all logo elements, see variants for guidance when customizing background images, widths, etc. */

.c-nav-curriculum__logo {
  font-size: 1rem;
  height: 2.25rem;
  padding: 0;
  text-indent: 102%;
  white-space: nowrap;
  overflow: hidden;
  background-position: left center;
  background-repeat: no-repeat;
  @media (--bp-mid) {
    & {
      border-right: solid .0625rem var(--text);
      margin: .8125rem 0; /* At this breakpoint, increase margin and decrease height to ensure right border is proportional to that in the lockup */
      height: 2.125rem; /* At this breakpoint, increase margin and decrease height to ensure right border is proportional to that in the lockup */
    }
  }
}

/* MS Math */

/* Replace link with curriculum logo background-image while keeping the link and its text accessible */

.c-nav-curriculum__logo_our6-8math,
.c-nav-curriculum__logo_our6-8math-nc,
.c-nav-curriculum__logo_our6-8math-tn,
.c-nav-curriculum__logo_our6-8math-wv,
.c-nav-curriculum__logo_our6-8math-v1,
.c-nav-curriculum__logo_our6-8math-v3 {
  width: 3rem;
  background-image: url('nav-curriculum/logos/our-symbol-msmath.svg');
  background-size: 14.3125rem;
    /* Since this is an OUR branded product, this width is calculated to match the OUR MS Math lockup to the OUR logo in the global nav. For projects with non-OUR branded lockups, this width match the aspect ratio of the lockup at 2.25rem tall. See Bookworms, EL K-5. */
  border-right: solid .0625rem black; /* exception for MS Math, show right border in narrow viewports */
}

/* HS Math */

/* Replace link with curriculum logo background-image while keeping the link and its text accessible */

.c-nav-curriculum__logo_our-hs-math,
.c-nav-curriculum__logo_our-hs-math-nc,
.c-nav-curriculum__logo_our-hs-math-tn,
.c-nav-curriculum__logo_our-hs-math-pilot-pl {
  width: 3rem;
  background-image: url('nav-curriculum/logos/our-symbol-hsmath.svg');
  background-size: 14.3125rem;
    /* Since this is an OUR branded product, this width is calculated to match the OUR HS Math lockup to the OUR logo in the global nav. For projects with non-OUR branded lockups, this width match the aspect ratio of the lockup at 2.25rem tall. See Bookworms, EL K-5. */
  border-right: solid .0625rem black; /* exception for HS Math, show right border in narrow viewports */
}

/* K5 Math */

/* Replace link with curriculum logo background-image while keeping the link and its text accessible */

.c-nav-curriculum__logo_our-k5-math,
.c-nav-curriculum__logo_our-k5-math-tn,
.c-nav-curriculum__logo_k8-math-pilot-pl {
  width: 3rem;
  background-image: url('nav-curriculum/logos/our-symbol-k5math.svg');
  background-size: 14.3125rem;
    /* Since this is an OUR branded product, this width is calculated to match the OUR K5 Math lockup to the OUR logo in the global nav. For projects with non-OUR branded lockups, this width match the aspect ratio of the lockup at 2.25rem tall. See Bookworms, EL K-5. */
  border-right: solid .0625rem black; /* exception for K5 Math, show right border in narrow viewports */
}

/* Odell */

/* Should be the same approach as we used for HS Math. */

.c-nav-curriculum__logo_odell-hs-literacy {
  width: 3rem;
  background-image: url('nav-curriculum/logos/our-symbol-odell.svg');
  background-size: 14.3125rem;
  border-right: solid .0625rem black; /* show right border in narrow viewports */
}

/* Bookworms */

/* Replace link with curriculum logo background-image while keeping the link and its text accessible */

.c-nav-curriculum__logo_bookworms,
.c-nav-curriculum__logo_bookworms-k5,
.c-nav-curriculum__logo_bookworms-pl-videos,
.c-nav-curriculum__logo_bookworms-pilot-pl {
  background-image: url('nav-curriculum/logos/bookworms-logotype-only.svg');
  background-size: calc(2.25rem * 8.6388888889); /* Match Bookworms lockup aspect ratio 311 x 36 */
  width: calc(2.25rem * 5.6666666667); /* Match aspect ratio of Bookworms logotype to "pipe" in lockup (204 x 36), thereby hiding the pipe and the description text to the right of the pipe */
  @media (--bp-mid) {
    & {
      background-image: url('nav-curriculum/logos/bookworms-lockup.svg');
    }
  }
}

/* EL K-5 */

/* Replace link with curriculum logo background-image while keeping the link and its text accessible */

.c-nav-curriculum__logo_el,
.c-nav-curriculum__logo_el-pilot-pl,
.c-nav-curriculum__logo_el-k5-2017,
.c-nav-curriculum__logo_el-ms-2019,
.c-nav-curriculum__logo_el-skills-block-2024 {
  background-image: url('nav-curriculum/logos/el-k8-logotype-only.svg');
  background-size: calc(2.25rem * 7.194444444); /* Match EL K-5 lockup aspect ratio 259 x 36 */
  width: calc(2.25rem * 4.6944444444); /* Match aspect ratio of EL K-5 logotype to "pipe" in lockup (169 x 36), thereby hiding the pipe and the description text to the right of the pipe */
  @media (--bp-mid) {
    & {
      background-image: url('nav-curriculum/logos/el-k8-lockup.svg');
    }
  }
}

/* Default section text styling */

.c-nav-curriculum__section-text {
  font-size: var(--zeta);
  color: var(--our-primary-dark-gray);
  text-decoration: none;
  display: none;
  align-self: center;
  @media (--bp-mid) {
    & {
      display: block;
      padding: 1.375rem 1.5rem 1rem .925rem;
    }
  }
}

/* Exception for Bookworms DI */

.c-nav-curriculum__section-text_bookworms-di {
  display: block;
  padding: calc(1.375rem - .65625em) 1.5rem calc(1rem - .65625em) .925rem;
  border-left: solid .0625rem var(--text);
  @media (--bp-mid) {
    & {
      border-left: none;
      padding: 1.375rem 1.5rem 1rem .925rem;
    }
  }
}

.c-nav-curriculum__section-text_bookworms-di::before {
  content: attr(data-title-short);
  @media (--bp-micro) {
    & {
      content: attr(data-title-medium);
    }
  }
  @media (--bp-mid) {
    & {
      content: "";
    }
  }
}

.c-nav-curriculum__section-text-swap {
  display: none;
  @media (--bp-mid) {
    & {
      display: inline;
    }
  }
}

/* Exception for HS Math, K5 Math, MS Math, & Odell HS Literacy */

.c-nav-curriculum__section-text_our-hs-math,
.c-nav-curriculum__section-text_our-hs-math-tn,
.c-nav-curriculum__section-text_our-hs-math-nc,
.c-nav-curriculum__section-text_our-k5-math,
.c-nav-curriculum__section-text_our-k5-math-tn,
.c-nav-curriculum__section-text_k8-math-pilot-pl,
.c-nav-curriculum__section-text_our6-8math,
.c-nav-curriculum__section-text_our6-8math-nc,
.c-nav-curriculum__section-text_our6-8math-tn,
.c-nav-curriculum__section-text_our6-8math-wv,
.c-nav-curriculum__section-text_our6-8math-v1,
.c-nav-curriculum__section-text_our6-8math-v3,
.c-nav-curriculum__section-text_odell-hs-literacy {
  display: block;
  padding: .5rem 1rem;
  @media (--bp-mid) {
    & {
      padding: 1rem 1.5rem 1rem .925rem;
    }
  }
}

.c-nav-curriculum__section-text-suffix {
  display: none;
  @media (--bp-micro) {
    & {
      display: inline-block;
    }
  }
}

.c-nav-curriculum__item {
  font-size: var(--zeta);
  margin: 0 0 0 -1rem;
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  outline: none;
  border-top: 1px solid var(--gray);
  @media (--bp-nano) {
    & {
      border-top: none;
      border-left: 1px solid var(--gray);
      margin: 0;
    }
  }
}

.c-nav-curriculum__item-title {
  display: block;
  font-weight: 500;
  position: relative;
  padding: .5rem 1rem;
  align-self: center;
  text-decoration: none;
  color: var(--our-primary-dark-gray);
  outline: none;
  @media (--bp-nano) {
    & {
      padding: .5rem 1rem;
    }
  }
  @media (--bp-mid) {
    & {
      padding: 1rem 1.25rem;
    }
  }
}

.c-nav-curriculum__item-title.is-current {
  color: var(--our-globalnav-orange);
  font-weight: 500;
  &:hover {
    color: var(--our-globalnav-orange);
  }
}

/* EN/ES Toggle */

.c-nav-curriculum__item_locale {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  background: url('nav-curriculum/globe-solid.svg');
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: 1rem center;
  border: none;
  margin-left: auto;
}

.c-nav-curriculum__label_locale {
  border-right: .0625rem solid var(--gray);
  padding: .275rem .375rem .275rem .4375rem;
  margin: .7rem 0;
  font-weight: 900;
  letter-spacing: .075em;
  line-height: 1;
  text-decoration: none;
}

.c-nav-curriculum__label_locale:first-child {
  padding: .275rem .375rem;
}

.c-nav-curriculum__label_locale:last-child {
  border-right: none;
  padding: .275rem 1rem .275rem .4375rem;
}

.c-nav-curriculum__label_locale.is-current {
  color: var(--our-globalnav-orange);
}

/* Inline Menu */

.c-nav-curriculum__item-menu {
  display: none;
  @media (--bp-mid) {
    & {
      display: flex;
      align-items: center;
      margin: 0;
      padding-right: 1rem;
    }
  }
}

.c-nav-curriculum__item-menu > li {
  list-style: none;
  margin: 0;
}

.c-nav-curriculum__item-menu > li > a {
  display: block;
  text-decoration: none;
  text-align: center;
  padding: .5rem;
}

.c-nav-curriculum__item-menu > li > a.is-current {
  color: var(--our-globalnav-orange);
  font-weight: 500;
  &:hover {
    color: var(--our-globalnav-orange);
  }
}
