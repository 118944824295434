/*----------------------------------------------------------------------------
 *
 * DIRECTORY
 *
 *---------------------------------------------------------------------------*/

.c-directory {
  max-width: var(--content-width);
  margin: auto;
  box-sizing: border-box;
}

.c-directory__sequence {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1rem auto;
  align-items: baseline;
  @media (--bp-mid) {
    & {
      flex-wrap: nowrap;
      margin: 2rem auto;
    }
  }
}

.c-directory__hed {
  font-size: var(--gamma);
  font-family: var(--font-family-display);
  font-weight: 600;
  margin: 0 2rem 1.5rem 0;
  line-height: 1.1;
  flex: 1 0 100%;
  box-sizing: border-box;
  @media (--bp-mid) {
    & {
      flex: 0 1 auto;
      margin: 0;
      margin-right: 3rem;
      font-size: var(--base);
      min-width: auto;
    }
  }
}

.c-directory__listing {
  list-style: none;
  margin: 0 2rem 1rem 0;
  flex: 0 1 auto;
  box-sizing: border-box;
  @media (--bp-mid) {
    & {
      flex: 0 1 auto;
      margin-right: 2rem;
    }
  }
}

.c-directory__subhed {
  font-size: var(--base);
  font-family: var(--font-family-display);
  font-weight: 700;
  margin: 0 0 1rem;
  line-height: 1.1;
}

.c-directory__sublisting {
  list-style: none;
  margin: 0;
  padding: 0;
}
