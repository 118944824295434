/* Vendor css and variables */
@import './publications/vendor/normalize.css'; /* Customized for OUR design */

/* Core css and variables */
@import './publications/core/_color.css';
@import './publications/core/_fonts.css';
@import './publications/core/_sizes.css';
@import './publications/core/_typography.css';
@import './publications/core/_button.css';

/* Layout */
@import './publications/layout/_layout.css';

/* Components */
@import './publications/components/_banner.css';
@import './publications/components/_announcement.css';
@import './publications/components/_cta-sidebar.css';
@import './publications/components/_directory.css';
@import './publications/components/_figure.css';
@import './publications/components/_nav-curriculum.css';
@import './publications/components/_index.css';

/* Shared Styles */
@import './shared/styles.css';
