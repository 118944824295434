/*----------------------------------------------------------------------------
 *
 * Colors
 *
 *---------------------------------------------------------------------------*/

:root {

    /* Colors with "-our-" in their name are sourced from the OUR brand guidelines. Pulled from Bookworms web. */

    --our-primary-orange:       rgb(252,76,2);    /* #FC4C02 */
    --our-globalnav-orange:     rgb(241,89,42);   /* #F1592A  - a less bright, WCAG AA+ compliant orange */
    --aa-orange:                rgb(215,65,2);    /* #d74102 - AA orange with white — used in the CMS, skews reddish */
    --our-primary-yellow:       rgb(255,184,28);  /* #FFB81C */
    --our-primary-gray:         rgb(110,98,89);   /* #6E6259 */
    --our-primary-dark-gray:    rgb(69,53,54);    /* #453536 */
    --gray:                     rgb(200,200,200);
    --accessible-gray:          rgb(119,119,119); /* The lightest gray that is accessible at < 18px text on white */
    --light-gray:               rgb(241,241,241);
    --sticky-note-yellow:       #fdf4a4; /* Used only for the annotations in the teacher manual, or similar future components */
    --teal:                     #008470; /* A secondary accent color, used for announcement and message backgrounds on the landing pages */

    /* Neutral and accent color variables */

    --black:      #000000;
    --text:       var(--our-primary-dark-gray);
    --dark-gray:  #4c4c4c; /* 70% black */
    --med-gray:   #bfbfbf; /* 25% black */
    --light-gray: #ebebeb; /* 8% black */
    --white:      #ffffff;
    --accent:     var(--aa-orange);
    --accent-large:     var(--our-globalnav-orange);
    --accent-alt: var(--teal);
    --wormsy:                   rgb(81, 187, 114); /* #51BB72 */
    --wormsy-aa:                rgb(52, 133, 78);  /* #34854e */
    --wormsy-aa-large:          rgb(66, 168, 98);  /* #42a862 */

}
